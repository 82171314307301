/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import SendFeedbackForm, { SendFeedbackFormValidationSchema } from './SendFeedbackForm';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import SendFeedbackActionsContainer from './SendFeedbackActionsContainer';
import { SendFeebackMachineService } from '../../../machines/definitions/sendFeedbackMachine';

const styles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #e6ded4;
  border-left: 1px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
  padding: 2em 4em;
`;

interface Props {
  machineService: SendFeebackMachineService;
}

const SendingFeedbackSection: React.FC<Props> = ({ machineService }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        message: '',
      }}
      validationSchema={SendFeedbackFormValidationSchema}
      onSubmit={() => {
        return;
      }}
    >
      {() => (
        <React.Fragment>
          <div css={styles}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">{t('feedback:title')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{t('feedback:intro')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <SendFeedbackForm />
              </Grid>
            </Grid>
          </div>
          <SendFeedbackActionsContainer machineService={machineService} />
        </React.Fragment>
      )}
    </Formik>
  );
};

export default SendingFeedbackSection;
