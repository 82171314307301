import { PartialObserver, Subject, Subscription } from 'rxjs';

class RxQueue<T = unknown> extends Subject<T> {
  public listen(observer: PartialObserver<T>): Subscription {
    return super.subscribe(observer);
  }

  public enqueue(item: T): RxQueue<T> {
    super.next(item);
    return this;
  }
}

export default RxQueue;
