import React from 'react';
import {
  MessageSender as ApiMessageSender,
  TextMessage as ApiTextMessage,
} from '../../../../../types/__graphql__/types';
import CustomerTextMessage from './CustomerTextMessage';
import Sk8terbotTextMessage from './Sk8terbotTextMessage';

interface Props {
  message: ApiTextMessage;
}

const TextMessage: React.FC<Props> = ({ message }) => {
  if (message.sender === ApiMessageSender.Sk8Terbot) {
    return <Sk8terbotTextMessage key={message.id}>{message.text}</Sk8terbotTextMessage>;
  }
  if (message.sender === ApiMessageSender.Customer) {
    return <CustomerTextMessage key={message.id}>{message.text}</CustomerTextMessage>;
  }
  return (
    <div key={message.id}>
      {message.sender}: {message.text}
    </div>
  );
};

export default TextMessage;
