import { createMachine, Interpreter, sendParent, StateSchema } from 'xstate';

export interface SendFeedbackMachineContextContract {}

export type SendFeedbackEvent = { type: 'SEND'; email: string; message: string };
export type CancelFeedbackSendingEvent = { type: 'CANCEL' };
export type SendFeedbackMachineEvent = SendFeedbackEvent | CancelFeedbackSendingEvent;

export function isSendFeedbackEvent(event: SendFeedbackMachineEvent): event is SendFeedbackEvent {
  return event && event.type === 'SEND' && 'email' in event && 'message' in event;
}

export type SendFeebackMachineService = Interpreter<
  SendFeedbackMachineContextContract,
  StateSchema,
  SendFeedbackMachineEvent
>;

export const sendFeedbackMachine = createMachine({
  id: 'sendFeedback',
  initial: 'writingFeedback',
  states: {
    writingFeedback: {
      entry: sendParent({
        type: 'WRITE_MESSAGE_AS_CUSTOMER',
        message: 'inputs:next_machine:feedback:leave_my_feedback',
      }),
      on: {
        CANCEL: 'feedbackCancelled',
        SEND: {
          target: 'sendingFeedback',
          actions: [
            sendParent((context, event) => {
              const typedEvent = event as SendFeedbackEvent;
              return {
                type: 'WRITE_MESSAGE_AS_CUSTOMER',
                message: 'feedback:customer_message',
                messageOptions: {
                  email: typedEvent.email,
                  message: typedEvent.message,
                },
              };
            }),
            sendParent({
              type: 'WRITE_MESSAGE_AS_SK8TERBOT',
              message: 'feedback:thanking_feedback_to_customer',
            }),
          ],
        },
      },
    },
    sendingFeedback: {
      invoke: {
        id: 'sendingFeedbackToBackend',
        src: 'sendFeedback',
        onDone: {
          target: 'feedbackSent',
        },
      },
    },
    feedbackCancelled: {
      type: 'final',
      entry: sendParent({
        type: 'WRITE_MESSAGE_AS_CUSTOMER',
        message: 'feedback:nothing_to_say',
      }),
    },
    feedbackSent: {
      type: 'final',
    },
  },
});
