import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import './fontawesome';
import DIContainerProvider from './diContainer/DIContainerProvider';
import { inspect } from '@xstate/inspect';
import { unstable_trace as trace } from 'scheduler/tracing';
import { Profiler } from 'react';
import { onRender } from './helpers/profiler';
import { GA4R } from 'ga-4-react';

// inspect({
//   iframe: false,
// });

trace('initial render', performance.now(), () => {
  ReactDOM.render(
    <Profiler id="Application" onRender={onRender}>
      <React.StrictMode>
        <GA4R code={`${process.env.REACT_APP_GA_MEASUREMENT_ID}`}>
          <DIContainerProvider>
            <Suspense fallback={'Loading...'}>
              <App />
            </Suspense>
          </DIContainerProvider>
        </GA4R>
      </React.StrictMode>
    </Profiler>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
