import { Ganga } from '../types/__graphql__/types';
import KnownSellerIds from '@gangabots/types/dist/sellers/KnownSellerIds';

export function getGangaImage(ganga: Ganga) {
  let sellerUrl;
  switch (ganga.sellerId) {
    case KnownSellerIds.FILLOW_NET:
      sellerUrl = 'http://www.fillow.net';
      break;
  }

  return `${sellerUrl}/${ganga.imageUrl}`;
}
