import { RecordProxy, RecordSourceProxy } from 'relay-runtime';
import { MessageSender } from '../../../../types/__graphql__/types';
import { min } from 'rxjs/operators';

export default class MessageFactory {
  private static nextMessageId = 1;

  static makeTextMessage(store: RecordSourceProxy, sender: MessageSender, text: string): RecordProxy {
    const node = store.create(`message:${MessageFactory.nextMessageId}`, 'TextMessage');

    node.setValue('TextMessage', '__typename');
    node.setValue(MessageFactory.nextMessageId, 'id');
    node.setValue(sender, 'sender');
    node.setValue(text, 'text');

    MessageFactory.nextMessageId++;

    return node;
  }

  static makeDisplayGangasMessage(
    store: RecordSourceProxy,
    productType: string,
    minPrice: number,
    maxPrice: number,
    afterCursor = ''
  ): RecordProxy {
    const node = store.create(`message:${MessageFactory.nextMessageId}`, 'GangasMessage');

    node.setValue('GangasMessage', '__typename');
    node.setValue(MessageFactory.nextMessageId, 'id');
    node.setValue(MessageSender.Sk8Terbot, 'sender');
    node.setValue(productType, 'productType');
    node.setValue(minPrice, 'priceStart');
    node.setValue(maxPrice, 'priceEnd');
    node.setValue(afterCursor, 'afterCursor');

    MessageFactory.nextMessageId++;

    return node;
  }
}
