import tw, { css, styled } from 'twin.macro';
import React, { useState } from 'react';
import { Button, Slider } from '@material-ui/core';

interface Props {
  onBudgetSelected(min: number, max: number): void;
}

const StyledSlider = styled(Slider)(
  () =>
    css`
      &.budgedSelector.MuiSlider-root {
        ${tw`mr-4 pb-0`}
        color: #48a08b;
        filter: brightness(80%);

        .MuiSlider-valueLabel {
          left: calc(-50% - 12px);
          transform: none;

          .PrivateValueLabel-circle-8 {
            width: 48px;
            height: 2em;
            border-radius: 10%;
            transform: none;

            .PrivateValueLabel-label-9 {
              transform: none;
            }
          }
        }
      }
    `
);

const BudgetSelector: React.FC<Props> = ({ onBudgetSelected }) => {
  const [budgetRange, setBudgetRange] = useState([0, 100]);

  return (
    <React.Fragment>
      <StyledSlider
        className="budgedSelector"
        min={0}
        max={400}
        step={10}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => <React.Fragment>{`${value} €`}</React.Fragment>}
        onChange={(event, value) => Array.isArray(value) && setBudgetRange(value)}
        value={budgetRange}
      />
      <Button variant="contained" color="primary" onClick={() => onBudgetSelected(budgetRange[0], budgetRange[1])}>
        Seleccionar
      </Button>
    </React.Fragment>
  );
};

export default BudgetSelector;
