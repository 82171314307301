import React from 'react';
import SendFeedbackActions from './SendFeedbackActions';
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';
import { SendFeedbackFormValues } from './SendFeedbackForm';
import { isEmpty } from 'lodash';
import { SendFeebackMachineService } from '../../../machines/definitions/sendFeedbackMachine';
import { useService } from '@xstate/react';

interface Props {
  machineService: SendFeebackMachineService;
}

const SendFeedbackActionsContainer: React.FC<Props> = ({ machineService }) => {
  const formik = useFormikContext<SendFeedbackFormValues>();
  const [_, send] = useService(machineService);

  const sendFeedback = (): void => {
    formik.validateForm().then((errors) => {
      if (!isEmpty(errors)) {
        formik.setTouched(setNestedObjectValues<FormikTouched<SendFeedbackFormValues>>(errors, true));
        return;
      }
      send({ type: 'SEND', ...formik.values });
    });
  };

  const cancelSending = (): void => {
    send('CANCEL');
  };

  return <SendFeedbackActions sendFeedback={sendFeedback} cancelSending={cancelSending} />;
};

export default SendFeedbackActionsContainer;
