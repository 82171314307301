/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import snakeCase from 'lodash/snakeCase';
import PropTypes, { InferProps } from 'prop-types';
import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import MachineType from '../../../machines/MachineType';

const { useCallback } = React;

interface NextMachineOption {
  value: MachineType;
  group: string;
}

const propTypes = {
  onSelectedMachine: PropTypes.func.isRequired,
};

type Props = InferProps<typeof propTypes> & { onSelectedMachine(selectedMachine: MachineType): void };

const options: NextMachineOption[] = [
  { value: MachineType.CREATE_GANGAHUNT, group: 'shopping' },
  { value: MachineType.LEAVE_FEEDBACK, group: 'feedback' },
];

const autocomboStyles = css`
  font-size: 0.8rem;
  color: #e0e0e0;
  background-color: white;
  width: 95%;
  border: 1px solid #e0e0e0;
  margin-right: 0.5rem;
`;

const NextMachineComboBox: React.FC<Props> = ({ onSelectedMachine }) => {
  const { t } = useTranslation(['inputs']);

  const getTranslatedOption = useCallback(
    (option: NextMachineOption) => {
      return t(`inputs:next_machine.${option.group}.${snakeCase(option.value)}`);
    },
    [t]
  );

  const handleNextMachineChange = useCallback(
    (event: ChangeEvent<unknown>, option: NextMachineOption | null, reason: AutocompleteChangeReason) => {
      if (reason === 'select-option') {
        if (option?.value) {
          onSelectedMachine(option.value);
          return;
        }
      }
    },
    [onSelectedMachine]
  );

  return (
    <React.Fragment>
      <Autocomplete<NextMachineOption>
        data-test-id="product-type-autocomplete"
        ListboxProps={{
          'data-test-id': 'product-type-autocomplete-listbox',
        }}
        size={'small'}
        options={options}
        groupBy={(option) => t(`inputs:next_machine.${option.group}.title`)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('inputs:next_machine.placeholder')}
            variant="outlined"
            data-test-id="product-type-autocomplete-input"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        getOptionLabel={(option) => getTranslatedOption(option)}
        renderOption={(option) => <React.Fragment>{getTranslatedOption(option)}</React.Fragment>}
        css={autocomboStyles}
        onChange={handleNextMachineChange}
      />
    </React.Fragment>
  );
};

NextMachineComboBox.propTypes = propTypes;

export default NextMachineComboBox;
