/** @jsxImportSource @emotion/react */
import { css, jsx, Theme } from '@emotion/react';
import React from 'react';
import { State } from 'xstate';
import NextMachineComboBoxContainer from './NextMachineComboBoxContainer';
import {
  Sk8terbotMachineContextContract,
  Sk8terbotMachineEvents,
} from '../../../machines/definitions/sk8terbotMachine';

interface Props {
  machineState: State<Sk8terbotMachineContextContract, Sk8terbotMachineEvents>;
}

const styles = (theme: Theme) => css`
  background-color: #f0f0f0;
  border-radius: 0 0 8px;
  border-top: 2px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.3rem 0 0.5rem;

  ${theme.breakpoints.up('md')} {
    padding: 0 2rem;
  }
`;

const InputPanel: React.FC<Props> = ({ machineState }) => {
  let input = null;

  switch (true) {
    case machineState.matches('mainActions.selecting'):
      input = <NextMachineComboBoxContainer />;
      break;
  }

  return <div css={styles}>{input}</div>;
};

export default InputPanel;
