import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Grid, TextField } from '@material-ui/core';

export interface SendFeedbackFormValues {
  email: string;
  message: string;
}

export const SendFeedbackFormValidationSchema = Yup.object().shape({
  email: Yup.string(),
  message: Yup.string().required('message is required'),
});

const SendFeedbackForm: React.FC = () => {
  const formik = useFormikContext<SendFeedbackFormValues>();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container item xs={12} spacing={5}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            multiline
            rows={8}
            variant="outlined"
            id="message"
            name="message"
            label="Message"
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default SendFeedbackForm;
