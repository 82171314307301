import DIContainerContract from './DiContainerContract';
import type { Environment } from 'relay-runtime';
import globalRelayEnvironment from '../data/relay/environment';
import type { i18n } from 'i18next';
import i18next from '../i18n';
import UserInputQueue from '../userInput/UserInputQueue';
import UserInputRepository from '../data/relay/sk8terbotState/userInputs/repositories/UserInputRepository';
import GangasSearchRepository from '../data/relay/sk8terbotState/gangasSearch/repositories/GangasSearchRepository';
import ViewerRepository from '../data/relay/viewer/repositories/ViewerRepository';

class GlobalDIContainer implements DIContainerContract {
  private _i18next: i18n;
  private readonly _gangasSearchRepository: GangasSearchRepository;
  private readonly _relayEnvironment: Environment;
  private readonly _userInputQueue: UserInputQueue;
  private readonly _userInputRepository: UserInputRepository;
  private readonly _viewerRepository: ViewerRepository;

  constructor(dependencies: Partial<DIContainerContract> = {}) {
    // Misc.
    this._i18next = dependencies.i18next ?? i18next;
    this._userInputQueue = dependencies.userInputQueue ?? new UserInputQueue();
    // Data Persistence
    this._relayEnvironment = dependencies.relayEnvironment ?? globalRelayEnvironment;
    // Repositories
    this._gangasSearchRepository =
      dependencies.gangasSearchRepository ?? new GangasSearchRepository(this._relayEnvironment);
    this._userInputRepository = dependencies.userInputRepository ?? new UserInputRepository(this._relayEnvironment);
    this._viewerRepository = dependencies.viewerRepository ?? new ViewerRepository(this._relayEnvironment);
  }

  get relayEnvironment(): Environment {
    return this._relayEnvironment;
  }

  get i18next(): i18n {
    return this._i18next;
  }

  set i18next(value: i18n) {
    this._i18next = value;
  }

  get userInputQueue(): UserInputQueue {
    return this._userInputQueue;
  }

  get userInputRepository(): UserInputRepository {
    return this._userInputRepository;
  }

  get viewerRepository(): ViewerRepository {
    return this._viewerRepository;
  }

  get gangasSearchRepository(): GangasSearchRepository {
    return this._gangasSearchRepository;
  }
}

export default GlobalDIContainer;
