/** @jsxImportSource @emotion/react */
import { jsx, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import React from 'react';

const styles = (theme: Theme) => css`
  background-color: #f0f0f0;
  border-radius: 0 0 8px;
  border-top: 2px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.3rem 0 0.5rem;

  ${theme.breakpoints.up('md')} {
    padding: 0 2rem;
  }
`;

const CreateGangaHuntActions: React.FC = ({ children }) => {
  return <div css={styles}>{children}</div>;
};

export default CreateGangaHuntActions;
