/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ChangeEvent, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clothesTypes from '@gangabots/types/dist/products/types/supported/clothesTypes';
import accessoriesTypes from '@gangabots/types/dist/products/types/supported/accessoriesTypes';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import skateboardingTypes from '@gangabots/types/dist/products/types/supported/skateboardingTypes';

interface ProductTypeOption {
  name: string;
  group: string;
  picture: string;
}

const propTypes = {
  onSelectedProductType: PropTypes.func.isRequired,
};

type Props = InferProps<typeof propTypes> & { onSelectedProductType(productType: string, picture: string): void };

// prettier-ignore
const options: ProductTypeOption[] = [
  { name: clothesTypes.tshirt.name, group: 'clothes', picture: 'https://www.fillow.net/images/products/zoom/1574937324-39828600.jpg' },
  { name: clothesTypes.shirt.name, group: 'clothes', picture: 'https://www.fillow.net/images/products/medium/1427459871-04459600.jpg' },
  { name: clothesTypes.sweatShirt.name, group: 'clothes', picture: 'https://www.fillow.net/images/products/medium/1607455583-75177600.jpg' },
  { name: clothesTypes.pants.name, group: 'clothes', picture: '' },
  { name: accessoriesTypes.backpack.name, group: 'accessories', picture: 'https://www.fillow.net/images/products/zoom/1539084179-72851900.jpg' },
  { name: accessoriesTypes.wallet.name, group: 'accessories', picture: 'https://www.fillow.net/images/products/medium/1593515029-62429000.jpg' },
  { name: accessoriesTypes.sunglasses.name, group: 'accessories', picture: 'https://www.fillow.net/images/products/medium/1562586505-16486700.jpg' },
  { name: accessoriesTypes.socks.name, group: 'accessories', picture: 'https://www.fillow.net/images/products/medium/1607681368-42864400.jpg' },
  { name: skateboardingTypes.deck.name, group: 'sk8board', picture: 'https://www.fillow.net/images/products/medium/1617103068-04848400.jpg' },
  { name: skateboardingTypes.griptape.name, group: 'sk8board', picture: 'https://www.fillow.net/images/products/medium/1611483882-97795700.jpg' },
  { name: skateboardingTypes.trucks.name, group: 'sk8board', picture: 'https://www.fillow.net/images/products/medium/1614171318-47498700.jpg' },
  { name: skateboardingTypes.wheels.name, group: 'sk8board', picture: 'https://www.fillow.net/images/products/medium/1616240270-94784600.jpg' },
];

const autocomboStyles = css`
  font-size: 0.8rem;
  color: #e0e0e0;
  background-color: white;
  width: 95%;
  border: 1px solid #e0e0e0;
  margin-right: 0.5rem;
`;

const ProductTypeAvatar = styled(Avatar)`
  margin-right: 0.3rem;
`;

const ProductTypeComboBox: React.FC<Props> = ({ onSelectedProductType }) => {
  const { t } = useTranslation(['inputs', 'productTypes']);

  const getTranslatedProductType = useCallback(
    (option: ProductTypeOption) => {
      return t(`productTypes:${option.group}.${option.name}_plural`);
    },
    [t]
  );

  const handleProductTypeChange = useCallback(
    (event: ChangeEvent<unknown>, option: ProductTypeOption | null, reason: AutocompleteChangeReason) => {
      if (reason === 'select-option') {
        onSelectedProductType(option?.name, option?.picture);
      }
    },
    [onSelectedProductType]
  );

  return (
    <React.Fragment>
      <Autocomplete<ProductTypeOption>
        data-test-id="product-type-autocomplete"
        ListboxProps={{
          'data-test-id': 'product-type-autocomplete-listbox',
        }}
        size={'small'}
        options={options}
        groupBy={(option) => t(`productTypes:group.${option.group}`)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('inputs:product_type_placeholder')}
            variant="outlined"
            data-test-id="product-type-autocomplete-input"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        getOptionLabel={(option) => getTranslatedProductType(option)}
        renderOption={(option) => (
          <React.Fragment>
            <ProductTypeAvatar variant="square" alt={getTranslatedProductType(option)} src={option.picture} />
            {getTranslatedProductType(option)}
          </React.Fragment>
        )}
        css={autocomboStyles}
        onChange={handleProductTypeChange}
      />
    </React.Fragment>
  );
};

ProductTypeComboBox.propTypes = propTypes;

export default ProductTypeComboBox;
