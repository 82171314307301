import React, { useMemo } from 'react';
import DIContainerContext from './DIContainerContext';
import GlobalDIContainer from './GlobalDIContainer';
import DIContainerContract from './DiContainerContract';

type Props = {
  diContainer?: DIContainerContract;
};

const DIContainerProvider: React.FC<Props> = ({ children, diContainer }) => {
  console.log('Rendering DIContainerProvider');
  const context = useMemo(() => {
    return diContainer ?? new GlobalDIContainer();
  }, [diContainer]);

  return <DIContainerContext.Provider value={context}>{children}</DIContainerContext.Provider>;
};

export default DIContainerProvider;
