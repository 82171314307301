import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { Environment, Network, RecordSource, Store, Variables } from 'relay-runtime';
import MessageListInitializer from './messages/MessageListInitializer';
import Sk8terbotStateInitializer from './sk8terbotState/Sk8terbotStateInitializer';
import HandlerProvider from './HandlerProvider';
import ViewerInitializer from './viewer/ViewerInitializer';

const amplifyConfiguration = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_GRAPHQL_REGION,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: process.env.REACT_APP_GRAPHQL_AUTHENTICATION_APIKEY,
};

Amplify.configure(amplifyConfiguration);

// @ts-ignore : Why? mismatch between amplify & relay types
function fetchQuery(operation, variables: Variables) {
  return API.graphql(graphqlOperation(operation.text, variables));
}

// @ts-ignore : Why? mismatch between amplify & relay types
function subscribe(operation, variables: Variables) {
  // @ts-ignore : Why? mismatch between amplify & relay types
  return API.graphql(graphqlOperation(operation.text, variables)).map(({ value }) => value);
}

// @ts-ignore : Why? mismatch between amplify & relay types
const network = Network.create(fetchQuery, subscribe);
const source = new RecordSource();
const store = new Store(source);
const handlerProvider = HandlerProvider;

const environment = new Environment({
  handlerProvider,
  network,
  store,
});

MessageListInitializer.initialize(environment);
Sk8terbotStateInitializer.initialize(environment);
ViewerInitializer.initialize(environment);

export default environment;
