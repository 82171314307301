import { assign, createMachine, Interpreter, sendParent, StateSchema } from 'xstate';

export interface CreateGangaHuntMachineContextContract {
  productType: string | undefined;
  productTypeImage: string | undefined;
  minPrice: number | undefined;
  maxPrice: number | undefined;
}

export type StopGangaHuntEvent = { type: 'STOP_GANGA_HUNT' };
export type SelectedProductTypeEvent = { type: 'SELECTED_PRODUCT_TYPE'; productType: string; productTypeImage: string };
export type SelectedBudgetEvent = { type: 'BUDGET_SELECTED'; minPrice: number; maxPrice: number };
export type ResetBudgetEvent = { type: 'RESET_BUDGET' };
export type CustomerWantsToStopGangaHauntEvent = { type: 'CUSTOMER_WANTS_TO_STOP_GANGA_HUNT' };
export type CustomerWantsToSeeMoreProductOfSameTypeEvent = {
  type: 'CUSTOMER_WANTS_TO_SEE_MORE_PRODUCTS_OF_SAME_TYPE';
};
export type CustomerWantsToSeeProductsOfDifferentTypeEvent = {
  type: 'CUSTOMER_WANTS_TO_SEE_PRODUCTS_OF_DIFFERENT_TYPE';
};
export type CreateGangaHuntMachineEvent =
  | StopGangaHuntEvent
  | SelectedProductTypeEvent
  | SelectedBudgetEvent
  | ResetBudgetEvent
  | CustomerWantsToStopGangaHauntEvent
  | CustomerWantsToSeeMoreProductOfSameTypeEvent
  | CustomerWantsToSeeProductsOfDifferentTypeEvent;

export type CreateGangaHuntMachineService = Interpreter<
  CreateGangaHuntMachineContextContract,
  StateSchema,
  CreateGangaHuntMachineEvent
>;

export const createGangaHuntMachine = createMachine<CreateGangaHuntMachineContextContract, CreateGangaHuntMachineEvent>(
  {
    id: 'shopping',
    initial: 'startGangaHunt',
    context: {
      productType: undefined,
      productTypeImage: undefined,
      minPrice: undefined,
      maxPrice: undefined,
    },
    on: {
      STOP_GANGA_HUNT: {
        target: 'endGangaHuntCreation',
      },
      RESET_BUDGET: {
        target: 'askForBudget',
        actions: assign<CreateGangaHuntMachineContextContract, ResetBudgetEvent>({
          minPrice: () => undefined,
          maxPrice: () => undefined,
        }),
      },
    },
    states: {
      startGangaHunt: {
        entry: [
          sendParent({
            type: 'WRITE_MESSAGE_AS_CUSTOMER',
            message: 'inputs:next_machine.shopping.create_a_ganga_hunt',
          }),
        ],
        always: 'askForProductType',
      },
      askForProductType: {
        entry: sendParent({
          type: 'WRITE_MESSAGE_AS_SK8TERBOT',
          message: 'workflows:create_ganga_hunt.select_product_type',
        }),
        on: {
          SELECTED_PRODUCT_TYPE: {
            target: 'selectedProductType',
            actions: [
              assign({
                productType: (context, event: SelectedProductTypeEvent) => event.productType,
                productTypeImage: (context, event: SelectedProductTypeEvent) => event.productTypeImage,
              }),
              sendParent((context) => ({
                type: 'WRITE_MESSAGE_AS_CUSTOMER',
                message: 'workflows:create_ganga_hunt.looking_for',
                messageOptions: {
                  productType: context.productType,
                },
              })),
            ],
          },
        },
      },
      selectedProductType: {
        always: 'askForBudget',
      },
      askForBudget: {
        on: {
          BUDGET_SELECTED: {
            target: 'loadingInitialProducts',
            actions: assign({
              minPrice: (context, event: SelectedBudgetEvent) => event.minPrice,
              maxPrice: (context, event: SelectedBudgetEvent) => event.maxPrice,
            }),
          },
        },
      },
      loadingInitialProducts: {
        invoke: {
          id: 'loadingInitialProducts',
          src: 'loadProductsByType',
          onDone: {
            target: 'askForProductSelection',
          },
        },
      },
      askForProductSelection: {
        entry: [
          sendParent({
            type: 'WRITE_MESSAGE_AS_SK8TERBOT',
            message: 'workflows:create_ganga_hunt.do_you_like_any_product',
          }),
        ],
        on: {
          CUSTOMER_WANTS_TO_STOP_GANGA_HUNT: {
            target: 'endGangaHuntCreation',
            actions: [
              sendParent({
                type: 'WRITE_MESSAGE_AS_CUSTOMER',
                message: 'workflows:create_ganga_hunt.i_do_not_like_any_product',
              }),
              sendParent({
                type: 'WRITE_MESSAGE_AS_CUSTOMER',
                message: 'workflows:create_ganga_hunt.stop_gangahunt',
              }),
            ],
          },
          CUSTOMER_WANTS_TO_SEE_MORE_PRODUCTS_OF_SAME_TYPE: {
            target: 'showMoreProducts',
            actions: sendParent({
              type: 'WRITE_MESSAGE_AS_CUSTOMER',
              message: 'workflows:create_ganga_hunt.display_more_products',
            }),
          },
          CUSTOMER_WANTS_TO_SEE_PRODUCTS_OF_DIFFERENT_TYPE: {
            target: 'askForProductType',
            actions: sendParent({
              type: 'WRITE_MESSAGE_AS_CUSTOMER',
              message: 'workflows:create_ganga_hunt.show_me_products_of_different_type',
            }),
          },
        },
      },
      showMoreProducts: {
        invoke: {
          id: 'loadingMoreProducts',
          src: 'loadMoreProductsByType',
          onDone: {
            target: 'askForProductSelection',
          },
        },
      },
      endGangaHuntCreation: {
        type: 'final',
      },
    },
  }
);
