import { commitLocalUpdate, Environment } from 'relay-runtime';
import { CustomerInputType } from '../../../types/__graphql__/types';

export default {
  initialize(environment: Environment): void {
    commitLocalUpdate(environment, (store) => {
      const root = store.getRoot();

      const sk8terbot = root.getOrCreateLinkedRecord('sk8terbot', 'Sk8terbot');
      const Sk8terbotState = sk8terbot.getOrCreateLinkedRecord('state', 'Sk8terbotState');
      Sk8terbotState.setLinkedRecords([], 'lastDisplayedGangas');
      Sk8terbotState.setLinkedRecords([], 'gangasSearchQueue');
      Sk8terbotState.setLinkedRecords([], 'userInputQueue');
    });
  },
};
