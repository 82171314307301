import { createMachine, send } from 'xstate';
import { createGangaHuntMachine } from './shopping/createGangaHuntMachine';
import { sendFeedbackMachine } from './sendFeedbackMachine';

export interface Sk8terbotMachineContextContract {}

export type WriteMessageAsSk8terbotEvent = {
  type: 'WRITE_MESSAGE_AS_SK8TERBOT';
  message: string;
  messageOptions?: unknown[];
};
export type WriteMessageAsCustomerEvent = {
  type: 'WRITE_MESSAGE_AS_CUSTOMER';
  message: string;
  messageOptions?: unknown[];
};
export type CreateGangaHuntEvent = { type: 'CREATE_GANGAHUNT' };
export type LeaveMyFeebackEvent = { type: 'LEAVE_MY_FEEDBACK' };

export type Sk8terbotMachineEvents =
  | WriteMessageAsSk8terbotEvent
  | WriteMessageAsCustomerEvent
  | CreateGangaHuntEvent
  | LeaveMyFeebackEvent;

export const sk8terbotMachine = createMachine<Sk8terbotMachineContextContract, Sk8terbotMachineEvents>({
  id: 'sk8terbot',
  initial: 'welcome',
  states: {
    welcome: {
      entry: [
        send({
          type: 'WRITE_MESSAGE_AS_SK8TERBOT',
          message: 'welcome:welcome_message',
        }),
        send({
          type: 'WRITE_MESSAGE_AS_SK8TERBOT',
          message: 'welcome:shop_description',
        }),
      ],
      always: 'mainActions.selecting',
    },
    mainActions: {
      states: {
        selecting: {
          entry: [
            send({
              type: 'WRITE_MESSAGE_AS_SK8TERBOT',
              message: 'workflows:request_for_general_action.ask_for_next_action',
            }),
          ],
          on: {
            CREATE_GANGAHUNT: '#sk8terbot.createGangaHunt',
            LEAVE_MY_FEEDBACK: '#sk8terbot.sendFeedback',
          },
        },
      },
    },
    createGangaHunt: {
      invoke: {
        id: 'createGangaHunt',
        src: createGangaHuntMachine,
        onDone: 'mainActions.selecting',
      },
    },
    sendFeedback: {
      invoke: {
        id: 'sendFeedback',
        src: sendFeedbackMachine,
        onDone: {
          target: 'mainActions.selecting',
        },
      },
    },
  },
  on: {
    WRITE_MESSAGE_AS_SK8TERBOT: {
      actions: ['writeMessageAsSk8terbot'],
    },
    WRITE_MESSAGE_AS_CUSTOMER: {
      actions: ['writeMessageAsCustomer'],
    },
  },
});
