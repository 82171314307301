/** @jsxImportSource @emotion/react */
import { jsx, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import React from 'react';

const styles = (theme: Theme) => css`
  background-color: white;
  border-radius: 3px;
  max-width: 70%;
  align-self: flex-start;
  margin-top: 0.5rem;
  color: black;
  margin-left: 1rem;
  padding: 0.5rem;
  font-size: 0.7rem;

  ${theme.breakpoints.up('md')} {
    margin-top: 0.8rem;
    margin-left: 3rem;
    padding: 0.5rem;
    border-radius: 8px;
  }
`;

const Sk8terbotTextMessage: React.FC = ({ children }) => {
  return (
    <div css={styles} className="chat-message-from-sk8terbot">
      {children}
    </div>
  );
};

export default Sk8terbotTextMessage;
