import {
  IDoNotLikeAnyProductInput,
  SelectedProductTypeInput,
  ShowMeMoreProductsOfSameTypeInput,
  ShowMeProductsOfDifferentTypeInput,
  UserInput,
  UserInputKind,
} from './types';
import IWantToStartAGangaHuntInput from '../../userInput/IWantToStartAGangaHuntInput';
import IWantToLeaveMyFeedbackInput from '../../userInput/IWantToLeaveMyFeedbackInput';

export function isIWantToStartAGangaHuntInput(input: UserInput): input is IWantToStartAGangaHuntInput {
  return input.kind === UserInputKind.IWantToStartAGangaHunt;
}

export function isIDoNotLikeAnyProductInput(input: UserInput): input is IDoNotLikeAnyProductInput {
  return input.kind === UserInputKind.IDoNotLikeAnyProduct;
}

export function isShowMeMoreProductsOfSameTypeInput(input: UserInput): input is ShowMeMoreProductsOfSameTypeInput {
  return input.kind === UserInputKind.ShowMeMoreProductsOfSameType;
}

export function isShowMeProductsOfDifferentTypeInput(input: UserInput): input is ShowMeProductsOfDifferentTypeInput {
  return input.kind === UserInputKind.ShowMeProductsOfDifferentType;
}

export function isSelectedProductTypeInput(input: UserInput): input is SelectedProductTypeInput {
  return input.kind === UserInputKind.SelectedProduct;
}

export function isIWantToLeaveMyFeedbackInput(input: UserInput): input is IWantToLeaveMyFeedbackInput {
  return input.kind === UserInputKind.IWantToLeaveMyFeedback;
}
