import { graphql } from 'babel-plugin-relay/macro';
import React from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';
import useLocalQuery from '../../../../data/relay/helpers/localQuery/useLocalQuery';
import ProductComboBox, { ProductComboBoxFixedOptions, ProductComboBoxOption } from './ProductComboBox';
import { CreateGangaHuntMachineService } from '../../../../machines/definitions/shopping/createGangaHuntMachine';
import { useService } from '@xstate/react';
import { ProductComboBoxContainerQueryResponse } from '../../../../__relay_artifacts__/ProductComboBoxContainerQuery.graphql';

interface Props {
  machineService: CreateGangaHuntMachineService;
}

const ProductComboBoxContainer: React.FC<Props> = ({ machineService }) => {
  const relayEnvironment = useRelayEnvironment();
  const query = graphql`
    query ProductComboBoxContainerQuery {
      ... on Query {
        __typename
      }
      sk8terbot {
        state {
          lastDisplayedGangas {
            __typename
            id
            sellerId
            title
            imageUrl
          }
        }
      }
    }
  `;

  const data = useLocalQuery({
    environment: relayEnvironment,
    query,
    variables: {},
  }) as ProductComboBoxContainerQueryResponse | null;

  const [state, send] = useService(machineService);

  const onSelectedInput = (input: ProductComboBoxOption): void => {
    if (input === ProductComboBoxFixedOptions.ShowMeMore) {
      send('CUSTOMER_WANTS_TO_SEE_MORE_PRODUCTS_OF_SAME_TYPE');
    }
    if (input === ProductComboBoxFixedOptions.ShowMeOtherProductTypes) {
      send('CUSTOMER_WANTS_TO_SEE_PRODUCTS_OF_DIFFERENT_TYPE');
    }
    if (input === ProductComboBoxFixedOptions.StopGangaHunt) {
      send('CUSTOMER_WANTS_TO_STOP_GANGA_HUNT');
    }
  };

  return <ProductComboBox gangas={data?.sk8terbot.state.lastDisplayedGangas ?? []} onInput={onSelectedInput} />;
};

export default ProductComboBoxContainer;
