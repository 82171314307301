import React from 'react';
import RelayEnvironment from '../../data/relay/environment';
import { ThemeProvider } from '@emotion/react';
import { StyledTheme } from '../../assets/styles/styled';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import MainPageContainer from '../../pages/main/MainPageContainer';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { GlobalStyles } from 'twin.macro';

const theme: StyledTheme = responsiveFontSizes(createMuiTheme());

const App: React.FC = () => {
  console.log('Rendering App');
  return (
    <React.Fragment>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <MainPageContainer />
        </RelayEnvironmentProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
