import i18nInstance, { i18n, InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

i18nInstance.use(XHR).use(LanguageDetector).use(initReactI18next);

i18nInstance.init({
  initImmediate: false,
  fallbackLng: 'es',
  debug: true,
  backend: {
    loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
  },
  ns: ['feedback', 'inputs', 'productTypes', 'shop', 'welcome', 'workflows'],
  detection: {
    order: ['cookie', 'localStorage', 'navigator', 'htmlTag'],
    caches: ['localStorage'],
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export function createNewI18nInstance(options: InitOptions = {}): i18n {
  const newInstance = i18nInstance.createInstance(options);
  newInstance.init(options);
  return newInstance;
}

export default i18nInstance;
