import React from 'react';
import { useService } from '@xstate/react';
import CreateGangaHuntFiltersBar, { CreateGangaHuntFilters } from './CreateGangaHuntFiltersBar';
import { CreateGangaHuntMachineService } from '../../../../machines/definitions/shopping/createGangaHuntMachine';

interface Props {
  machineService: CreateGangaHuntMachineService;
}

const CreateGangaHuntFiltersBarContainer: React.FC<Props> = ({ machineService }) => {
  const [state, send] = useService(machineService);

  const filters: CreateGangaHuntFilters = {
    productType: state.context.productType,
    productTypeImage: state.context.productTypeImage,
    minPrice: state.context.minPrice,
    maxPrice: state.context.maxPrice,
  };

  const stopGangaHunt = () => {
    send('STOP_GANGA_HUNT');
  };

  const selectNewPriceRange = () => {
    send('RESET_BUDGET');
  };

  return (
    <CreateGangaHuntFiltersBar
      filters={filters}
      onProductTypeFilterDeleted={stopGangaHunt}
      onPriceRangeFilterDeleted={selectNewPriceRange}
    />
  );
};

export default CreateGangaHuntFiltersBarContainer;
