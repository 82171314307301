export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Node = {
  id: Scalars['ID'];
};

export type UserCommand = {
  kind: UserCommandKind;
};

export type CreateUserEventPayload = {
  __typename?: 'CreateUserEventPayload';
  clientMutationId?: Maybe<Scalars['ID']>;
  edge?: Maybe<UserEventEdge>;
};

export type Ganga = Node & {
  __typename?: 'Ganga';
  amount: Scalars['String'];
  currency: Scalars['String'];
  description: Scalars['String'];
  detailsUrl: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  sellerId: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type GangaConnection = {
  __typename?: 'GangaConnection';
  edges: Array<GangaEdge>;
  pageInfo: PageInfo;
};

export type GangaEdge = {
  __typename?: 'GangaEdge';
  cursor: Scalars['String'];
  node: Ganga;
};

export type IDoNotLikeAnyProductCommand = UserCommand & {
  __typename?: 'IDoNotLikeAnyProductCommand';
  kind: UserCommandKind;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUserEvent: CreateUserEventPayload;
};

export type MutationCreateUserEventArgs = {
  input?: Maybe<CreateUserEventInput>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ProductType = {
  __typename?: 'ProductType';
  code: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allGangas: GangaConnection;
  allMessages: MessageConnection;
  gangas: GangaConnection;
  sk8terbot: Sk8terbot;
  viewer: Viewer;
};

export type QueryAllMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryGangasArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type SelectedProductCommand = UserCommand & {
  __typename?: 'SelectedProductCommand';
  kind: UserCommandKind;
  productId: Scalars['ID'];
};

export type SelectedProductTypeCommand = UserCommand & {
  __typename?: 'SelectedProductTypeCommand';
  kind: UserCommandKind;
  productType: Scalars['String'];
};

export type ShowMeMoreProductsOfSameTypeCommand = UserCommand & {
  __typename?: 'ShowMeMoreProductsOfSameTypeCommand';
  kind: UserCommandKind;
};

export type ShowMeProductsOfDifferentTypeCommand = UserCommand & {
  __typename?: 'ShowMeProductsOfDifferentTypeCommand';
  kind: UserCommandKind;
};

export type UserEvent = Node & {
  __typename?: 'UserEvent';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
};

export type UserEventEdge = {
  __typename?: 'UserEventEdge';
  cursor?: Maybe<Scalars['String']>;
  node: UserEvent;
};

export type Viewer = Node & {
  __typename?: 'Viewer';
  id: Scalars['ID'];
};

export enum UserCommandKind {
  IDoNotLikeAnyProduct = 'IDoNotLikeAnyProduct',
  SelectedProduct = 'SelectedProduct',
  SelectedProductType = 'SelectedProductType',
  ShowMeMoreProductsOfSameType = 'ShowMeMoreProductsOfSameType',
  ShowMeProductsOfDifferentType = 'ShowMeProductsOfDifferentType',
}

export type CreateUserEventInput = {
  clientMutationId?: Maybe<Scalars['ID']>;
  command: UserCommandInput;
  viewerId?: Maybe<Scalars['ID']>;
};

export type UserCommandInput = {
  kind: UserCommandKind;
  productId?: Maybe<Scalars['ID']>;
  productType?: Maybe<Scalars['String']>;
};

export enum MessageSender {
  Sk8Terbot = 'SK8TERBOT',
  Customer = 'CUSTOMER',
}

export type Message = {
  id: Scalars['ID'];
  __typename: Scalars['String'];
  sender: MessageSender;
};

export type TextMessage = Message & {
  __typename?: 'TextMessage';
  text: Scalars['String'];
};

export type GangasMessage = Message & {
  __typename?: 'GangasMessage';
  productType: Scalars['String'];
  priceStart: Scalars['Float'];
  priceEnd: Scalars['Float'];
  afterCursor?: Maybe<Scalars['String']>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String'];
  node: Message;
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
};

export type GangasSearch = {
  __typename?: 'GangasSearch';
  productTypeCode: Scalars['String'];
  endCursor?: Maybe<Scalars['String']>;
};

export type Sk8terbotState = {
  __typename?: 'Sk8terbotState';
  lastDisplayedGangas: Array<Ganga>;
  userInputQueue: Array<UserInput>;
  gangasSearchQueue: Array<GangasSearch>;
};

export type Sk8terbot = {
  __typename?: 'Sk8terbot';
  state: Sk8terbotState;
};

export enum CustomerInputType {
  None = 'NONE',
  Text = 'TEXT',
  ProductType = 'PRODUCT_TYPE',
  LikableProduct = 'LIKABLE_PRODUCT',
  NextWorkflow = 'NEXT_WORKFLOW',
}

export enum UserInputKind {
  IWantToStartAGangaHunt = 'IWantToStartAGangaHunt',
  IWantToLeaveMyFeedback = 'IWantToLeaveMyFeedback',
  SelectedProductType = 'SelectedProductType',
  SelectedProduct = 'SelectedProduct',
  IDoNotLikeAnyProduct = 'IDoNotLikeAnyProduct',
  ShowMeMoreProductsOfSameType = 'ShowMeMoreProductsOfSameType',
  ShowMeProductsOfDifferentType = 'ShowMeProductsOfDifferentType',
}

export type UserInput = {
  kind?: Maybe<UserInputKind>;
};

export type IWantToLeaveMyFeedbackInput = UserInput & {
  __typename?: 'IWantToLeaveMyFeedbackInput';
  kind?: Maybe<UserInputKind>;
};

export type IWantToStartAGangaHuntInput = UserInput & {
  __typename?: 'IWantToStartAGangaHuntInput';
  kind?: Maybe<UserInputKind>;
};

export type SelectedProductTypeInput = UserInput & {
  __typename?: 'SelectedProductTypeInput';
  kind?: Maybe<UserInputKind>;
  productType: Scalars['String'];
};

export type SelectedProductInput = UserInput & {
  __typename?: 'SelectedProductInput';
  kind?: Maybe<UserInputKind>;
  productId: Scalars['ID'];
};

export type IDoNotLikeAnyProductInput = UserInput & {
  __typename?: 'IDoNotLikeAnyProductInput';
  kind?: Maybe<UserInputKind>;
};

export type ShowMeMoreProductsOfSameTypeInput = UserInput & {
  __typename?: 'ShowMeMoreProductsOfSameTypeInput';
  kind?: Maybe<UserInputKind>;
};

export type ShowMeProductsOfDifferentTypeInput = UserInput & {
  __typename?: 'ShowMeProductsOfDifferentTypeInput';
  kind?: Maybe<UserInputKind>;
};
