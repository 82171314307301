/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type MessageSender = "CUSTOMER" | "SK8TERBOT" | "%future added value";
export type MainPageRelayMessagesProviderQueryVariables = {};
export type MainPageRelayMessagesProviderQueryResponse = {
    readonly __typename: string;
    readonly allMessages: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly __typename: string;
                readonly id: string;
                readonly sender: MessageSender;
                readonly text?: string;
                readonly productType?: string;
                readonly priceStart?: number;
                readonly priceEnd?: number;
                readonly afterCursor?: string | null;
            };
        }>;
    };
};
export type MainPageRelayMessagesProviderQuery = {
    readonly response: MainPageRelayMessagesProviderQueryResponse;
    readonly variables: MainPageRelayMessagesProviderQueryVariables;
};



/*
query MainPageRelayMessagesProviderQuery {
  __typename
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageConnection",
        "kind": "LinkedField",
        "name": "allMessages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sender",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "type": "TextMessage",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priceStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priceEnd",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "afterCursor",
                        "storageKey": null
                      }
                    ],
                    "type": "GangasMessage",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MainPageRelayMessagesProviderQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MainPageRelayMessagesProviderQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8a87369501ca9402a2878b4455432092",
    "id": null,
    "metadata": {},
    "name": "MainPageRelayMessagesProviderQuery",
    "operationKind": "query",
    "text": "query MainPageRelayMessagesProviderQuery {\n  __typename\n}\n"
  }
};
})();
(node as any).hash = '37deb2de5e2969fe6e68f66f6c5aab25';
export default node;
