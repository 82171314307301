import { useContext } from 'react';
import Sk8terbotMachineContext from '../Sk8terbotMachineContext';
import { Interpreter } from 'xstate';
import { StateSchema, Typestate } from 'xstate/lib/types';
import { Sk8terbotMachineContextContract, Sk8terbotMachineEvents } from '../definitions/sk8terbotMachine';

function useSk8terbotMachine(): Interpreter<
  Sk8terbotMachineContextContract,
  StateSchema,
  Sk8terbotMachineEvents,
  Typestate<Sk8terbotMachineContextContract>
> {
  const context = useContext(Sk8terbotMachineContext);

  if (context === undefined) {
    throw new Error('useSk8terbotMachine must be used within a machine interpreter');
  }

  return context;
}

export default useSk8terbotMachine;
