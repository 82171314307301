import React, { Profiler, useState } from 'react';
import { onRender } from '../../helpers/profiler';
import MainPageRelayMessagesProvider from './MainPageRelayMessagesProvider';
import MainPage from './MainPage';
import Sk8terbotMachineProvider from '../../machines/Sk8terbotMachineProvider';
import {
  sk8terbotMachine,
  Sk8terbotMachineContextContract,
  Sk8terbotMachineEvents,
  WriteMessageAsCustomerEvent,
  WriteMessageAsSk8terbotEvent,
} from '../../machines/definitions/sk8terbotMachine';
import { useTranslation } from 'react-i18next';
import { useMachine } from '@xstate/react';
import AddSk8terbotMessageMutation from '../../data/relay/messages/mutations/AddSk8terbotMessageMutation';
import AddCustomerMessageMutation from '../../data/relay/messages/mutations/AddCustomerMessageMutation';
import { useRelayEnvironment } from 'react-relay/hooks';

const MainPageContainer: React.FC = () => {
  const { t } = useTranslation();
  const relayEnvironment = useRelayEnvironment();
  const [allMessages, setAllMessages] = useState([]);

  const [state, send, service] = useMachine<Sk8terbotMachineContextContract, Sk8terbotMachineEvents>(sk8terbotMachine, {
    devTools: true,
    actions: {
      writeMessageAsSk8terbot: (context, event) => {
        const data = event as WriteMessageAsSk8terbotEvent;
        AddSk8terbotMessageMutation.commit(relayEnvironment, t(data.message, data.messageOptions ?? []));
      },
      writeMessageAsCustomer: (context, event) => {
        const data = event as WriteMessageAsCustomerEvent;
        AddCustomerMessageMutation.commit(relayEnvironment, t(data.message, data.messageOptions ?? []));
      },
    },
  });

  return (
    <React.Fragment>
      <Profiler id="MainPageContainer" onRender={onRender}>
        <MainPageRelayMessagesProvider setAllMessages={setAllMessages} />
        <Sk8terbotMachineProvider service={service}>
          <MainPage messages={(allMessages as any) ?? []} machineState={state} />
        </Sk8terbotMachineProvider>
      </Profiler>
    </React.Fragment>
  );
};

export default MainPageContainer;
