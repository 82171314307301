/** @jsxImportSource @emotion/react */
import { css, jsx, Theme } from '@emotion/react';
import React from 'react';

const styles = (theme: Theme) => css`
  align-self: flex-end;
  background-color: #d0ffc5;
  border-radius: 4px;
  color: black;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.7rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  max-width: 70%;
  padding: 0.5rem;

  ${theme.breakpoints.up('md')} {
    margin-top: 0.8rem;
    margin-right: 3rem;
    padding: 0.5rem;
    border-radius: 8px;
  }
`;

const CustomerTextMessage: React.FC = ({ children }) => {
  return <div css={styles}>{children}</div>;
};

export default CustomerTextMessage;
