/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import tw, { css, styled } from 'twin.macro';
import React from 'react';
import CreateGangaHuntActionsContainer from './CreateGangaHuntActionsContainer';
import { CreateGangaHuntMachineService } from '../../../machines/definitions/shopping/createGangaHuntMachine';
import CreateGangaHuntFiltersBarContainer from './filtersBar/CreateGangaHuntFiltersBarContainer';

interface Props {
  machineService: CreateGangaHuntMachineService;
}

const StyledSection = styled.div(() => [
  tw`grid grid-cols-1 grid-rows-2`,
  css`
    overflow: hidden;
    grid-template-rows: 2em auto;
  `,
]);

const CreateGangaHuntSection: React.FC<Props> = ({ machineService }) => {
  return (
    <StyledSection>
      {machineService ? <CreateGangaHuntFiltersBarContainer machineService={machineService} /> : null}
      {machineService ? <CreateGangaHuntActionsContainer machineService={machineService} /> : null}
    </StyledSection>
  );
};

export default CreateGangaHuntSection;
