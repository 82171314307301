import React from 'react';
import Sk8terbotMachineContext from './Sk8terbotMachineContext';
import { Interpreter } from 'xstate';
import { Sk8terbotMachineContextContract, Sk8terbotMachineEvents } from './definitions/sk8terbotMachine';
import { StateSchema, Typestate } from 'xstate/lib/types';

type Props = {
  service: Interpreter<
    Sk8terbotMachineContextContract,
    StateSchema,
    Sk8terbotMachineEvents,
    Typestate<Sk8terbotMachineContextContract>
  >;
};

const Sk8terbotMachineProvider: React.FC<Props> = ({ children, service }) => {
  return <Sk8terbotMachineContext.Provider value={service}>{children}</Sk8terbotMachineContext.Provider>;
};

export default Sk8terbotMachineProvider;
