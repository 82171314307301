import React from 'react';
import { useService } from '@xstate/react';
import useSk8terbotMachine from '../../../machines/hooks/useSk8terbotMachine';
import MachineType from '../../../machines/MachineType';
import NextMachineComboBox from './NextMachineComboBox';

const NextMachineComboBoxContainer: React.FC = () => {
  const sk8terbotService = useSk8terbotMachine();
  const [state, send] = useService(sk8terbotService);

  return (
    <NextMachineComboBox
      onSelectedMachine={(selectedMachine: MachineType): void => {
        if (selectedMachine === MachineType.LEAVE_FEEDBACK) {
          send('LEAVE_MY_FEEDBACK');
        }
        if (selectedMachine === MachineType.CREATE_GANGAHUNT) {
          send('CREATE_GANGAHUNT');
        }
      }}
    />
  );
};

export default NextMachineComboBoxContainer;
