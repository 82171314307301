/** @jsxImportSource @emotion/react */
import { Theme } from '@emotion/react';
import tw, { css, styled } from 'twin.macro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Sk8terbot from '../../assets/images/skaterbot.jpg';
import { Paper } from '@material-ui/core';
import ChatMessages from './chat/ChatMessages';
import SendingFeedbackSectionContainer from './feedback/SendingFeedbackSectionContainer';
import MainToolbar from './MainToolbar';
import { State } from 'xstate';
import MainInputPanel from './input/MainInputPanel';
import CreateGangaHuntSectionContainer from './createGangaHunt/CreateGangaHuntSectionContainer';
import { Sk8terbotMachineContextContract, Sk8terbotMachineEvents } from '../../machines/definitions/sk8terbotMachine';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';
import { useGA4React } from 'ga-4-react';

interface Props {
  machineState: State<Sk8terbotMachineContextContract, Sk8terbotMachineEvents>;
  messages: any;
  ga4?: GA4ReactResolveInterface;
}

const LeftColumn = styled.div(({ theme }) => [
  tw`h-full`,
  css`
    background-color: #ededed;
    padding: 0.5rem;
    ${theme.breakpoints.up('md')} {
      padding: 1rem;
      border-radius: 5px 0 0 5px;
    }
  `,
]);

const RightColumn = styled.div(() => [
  tw`h-full w-full grid`,
  css`
    overflow: hidden;
    border-radius: 0 5px 5px;
    grid-template-rows: 10% 75% 15%;
  `,
]);

const Sk8terbotAvatar = styled.img`
  ${({ theme }) => `
    border-radius: 1px;
    width: 32px;
    ${theme.breakpoints.up('md')} {
      border-radius: 8px;
      width: 100px;
    }
  `}
`;

const pageStyles = (theme: Theme) => [
  tw`flex h-screen rounded-none`,
  css`
    background-image: linear-gradient(to bottom, #009f8a 10rem, #d9ddd4 0%);
    font-family: 'Roboto', sans-serif;
    ${theme.breakpoints.up('md')} {
      ${tw`px-16 py-10`}
    }
  `,
];

const MainPage: React.FC<Props> = ({ messages, machineState }) => {
  const { t } = useTranslation();
  const ga4 = useGA4React() as GA4ReactResolveInterface;
  const [displayChatMessages, setDisplayChatMessages] = useState<boolean>(true);

  useEffect(() => {
    switch (true) {
      case machineState.matches('sendFeedback'):
        setDisplayChatMessages(false);
        break;
      case machineState.matches('createGangaHunt'):
        setDisplayChatMessages(true);
        break;
      default:
        setDisplayChatMessages(true);
    }
  }, [machineState, setDisplayChatMessages]);

  useEffect(() => {
    ga4?.pageview('main_page', 'main_page', 'main_page');
  }, [ga4]);

  const renderMainContent = () => {
    switch (true) {
      case machineState.matches('sendFeedback'):
        return <SendingFeedbackSectionContainer />;
      case machineState.matches('createGangaHunt'):
        return <CreateGangaHuntSectionContainer />;
      default:
        return (
          <React.Fragment>
            <MainInputPanel machineState={machineState} />
          </React.Fragment>
        );
    }
  };

  return (
    <Paper css={pageStyles}>
      <LeftColumn className="left-column">
        <Sk8terbotAvatar className="sk8terbot" src={Sk8terbot} />
      </LeftColumn>
      <RightColumn className="right-column">
        <MainToolbar>{t('shop:chat_header_title')}</MainToolbar>
        <ChatMessages messages={messages?.edges ?? []} isVisible={displayChatMessages} />
        {renderMainContent()}
      </RightColumn>
    </Paper>
  );
};

export default MainPage;
