import React from 'react';
import { Interpreter } from 'xstate';
import { StateSchema, Typestate } from 'xstate/lib/types';
import { Sk8terbotMachineContextContract, Sk8terbotMachineEvents } from './definitions/sk8terbotMachine';

export default React.createContext<
  | Interpreter<
      Sk8terbotMachineContextContract,
      StateSchema,
      Sk8terbotMachineEvents,
      Typestate<Sk8terbotMachineContextContract>
    >
  | undefined
>(undefined);
