import { createOperationDescriptor, Environment, getRequest } from 'relay-runtime';
import { graphql } from 'babel-plugin-relay/macro';
import { GangasSearch } from '../../../../../types/__graphql__/types';
import { GangasSearchRepositoryGetLastQueryResponse } from '../../../../../__relay_artifacts__/GangasSearchRepositoryGetLastQuery.graphql';

class GangasSearchRepository {
  private _relayEnvironment: Environment;

  constructor(relayEnvironment: Environment) {
    this._relayEnvironment = relayEnvironment;
  }

  static readonly getLastQuery = graphql`
    query GangasSearchRepositoryGetLastQuery {
      ... on Query {
        __typename
      }
      sk8terbot {
        state {
          gangasSearchQueue {
            __typename
            productTypeCode
            endCursor
          }
        }
      }
    }
  `;

  public getLast(): GangasSearch | undefined {
    const request = getRequest(GangasSearchRepository.getLastQuery);
    const operation = createOperationDescriptor(request, {});
    this._relayEnvironment.check(operation);
    const res = this._relayEnvironment.lookup(operation.fragment);
    if (res === undefined) {
      return undefined;
    }

    const data = res.data as GangasSearchRepositoryGetLastQueryResponse;
    return (data.sk8terbot?.state?.gangasSearchQueue?.[0] as GangasSearch) ?? undefined;
  }
}

export default GangasSearchRepository;
