import { commitLocalUpdate, ConnectionHandler, Environment, RecordProxy } from 'relay-runtime';
import MessageFactory from '../factories/MessageFactory';
import { assertIsDefined } from '@gangabots/types/dist/assertions';

function commit(
  environment: Environment,
  productType: string,
  minPrice: number,
  maxPrice: number,
  afterCursor?: string
): void {
  commitLocalUpdate(environment, (store) => {
    const allMessages = store.get('client:root:allMessages');
    assertIsDefined<RecordProxy>(allMessages);

    ConnectionHandler.insertEdgeAfter(
      allMessages,
      ConnectionHandler.createEdge(
        store,
        allMessages,
        MessageFactory.makeDisplayGangasMessage(store, productType, minPrice, maxPrice, afterCursor),
        'MessageEdge'
      )
    );
  });
}

export default { commit };
