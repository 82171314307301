/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProductComboBoxContainerQueryVariables = {};
export type ProductComboBoxContainerQueryResponse = {
    readonly __typename: string;
    readonly sk8terbot: {
        readonly state: {
            readonly lastDisplayedGangas: ReadonlyArray<{
                readonly __typename: string;
                readonly id: string;
                readonly sellerId: string;
                readonly title: string;
                readonly imageUrl: string;
            }>;
        };
    };
};
export type ProductComboBoxContainerQuery = {
    readonly response: ProductComboBoxContainerQueryResponse;
    readonly variables: ProductComboBoxContainerQueryVariables;
};



/*
query ProductComboBoxContainerQuery {
  __typename
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Sk8terbot",
        "kind": "LinkedField",
        "name": "sk8terbot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Sk8terbotState",
            "kind": "LinkedField",
            "name": "state",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Ganga",
                "kind": "LinkedField",
                "name": "lastDisplayedGangas",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sellerId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductComboBoxContainerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProductComboBoxContainerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "83540906336bd178267a4277ed9ec77b",
    "id": null,
    "metadata": {},
    "name": "ProductComboBoxContainerQuery",
    "operationKind": "query",
    "text": "query ProductComboBoxContainerQuery {\n  __typename\n}\n"
  }
};
})();
(node as any).hash = '532f6dc6c9e5a1eaec3dda502abc3b0c';
export default node;
