import RxQueue from './RxQueue';
import UserInput from './ UserInput';
import { PartialObserver, Subscription } from 'rxjs';

class UserInputQueue extends RxQueue<UserInput> {
  public listen(observer: PartialObserver<UserInput>): Subscription {
    return super.listen(observer);
  }

  public enqueue(item: UserInput): RxQueue<UserInput> {
    return super.enqueue(item);
  }
}

export default UserInputQueue;
