/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GangasSearchRepositoryGetLastQueryVariables = {};
export type GangasSearchRepositoryGetLastQueryResponse = {
    readonly __typename: string;
    readonly sk8terbot: {
        readonly state: {
            readonly gangasSearchQueue: ReadonlyArray<{
                readonly __typename: string;
                readonly productTypeCode: string;
                readonly endCursor: string | null;
            }>;
        };
    };
};
export type GangasSearchRepositoryGetLastQuery = {
    readonly response: GangasSearchRepositoryGetLastQueryResponse;
    readonly variables: GangasSearchRepositoryGetLastQueryVariables;
};



/*
query GangasSearchRepositoryGetLastQuery {
  __typename
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Sk8terbot",
        "kind": "LinkedField",
        "name": "sk8terbot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Sk8terbotState",
            "kind": "LinkedField",
            "name": "state",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GangasSearch",
                "kind": "LinkedField",
                "name": "gangasSearchQueue",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productTypeCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GangasSearchRepositoryGetLastQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GangasSearchRepositoryGetLastQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6d5f50cf1593e2a700abea02c8a33a72",
    "id": null,
    "metadata": {},
    "name": "GangasSearchRepositoryGetLastQuery",
    "operationKind": "query",
    "text": "query GangasSearchRepositoryGetLastQuery {\n  __typename\n}\n"
  }
};
})();
(node as any).hash = '8fc374bf740aa6dfe70fb535edf86254';
export default node;
