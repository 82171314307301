import React from 'react';
import { CreateGangaHuntMachineService } from '../../../../machines/definitions/shopping/createGangaHuntMachine';
import { useService } from '@xstate/react';
import BudgetSelector from './BudgetSelector';

interface Props {
  machineService: CreateGangaHuntMachineService;
}

const BudgetSelectorContainer: React.FC<Props> = ({ machineService }) => {
  const [_, send] = useService(machineService);

  const onBudgetSelected = (minPrice: number, maxPrice: number): void => {
    send({ type: 'BUDGET_SELECTED', minPrice, maxPrice });
  };

  return <BudgetSelector onBudgetSelected={onBudgetSelected} />;
};

export default BudgetSelectorContainer;
