import React from 'react';
import { useService } from '@xstate/react';
import CreateGangaHuntActions from './CreateGangaHuntActions';
import { CreateGangaHuntMachineService } from '../../../machines/definitions/shopping/createGangaHuntMachine';
import ProductTypeComboBoxContainer from './input/ProductTypeComboBoxContainer';
import ProductComboBoxContainer from './input/ProductComboBoxContainer';
import BudgetSelectorContainer from './input/BudgetSelectorContainer';

interface Props {
  machineService: CreateGangaHuntMachineService;
}

const CreateGangaHuntActionsContainer: React.FC<Props> = ({ machineService }) => {
  const [state, send] = useService(machineService);

  const renderActionsPanel = () => {
    switch (true) {
      case state.matches('askForProductType'):
        return <ProductTypeComboBoxContainer machineService={machineService} />;
      case state.matches('askForBudget'):
        return <BudgetSelectorContainer machineService={machineService} />;
      case state.matches('askForProductSelection'):
        return <ProductComboBoxContainer machineService={machineService} />;
      default:
        return null;
    }
  };

  return <CreateGangaHuntActions>{renderActionsPanel()}</CreateGangaHuntActions>;
};

export default CreateGangaHuntActionsContainer;
