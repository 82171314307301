/** @jsxImportSource @emotion/react */
import { css, styled } from 'twin.macro';
import React from 'react';
import { Avatar, Chip } from '@material-ui/core';

export interface CreateGangaHuntFilters {
  productType: string | undefined;
  productTypeImage: string | undefined;
  minPrice: number | undefined;
  maxPrice: number | undefined;
}

type ProductTypeChipProps = Pick<CreateGangaHuntFilters, 'productType' | 'productTypeImage'> & {
  onProductTypeFilterDeleted(): void;
};

const ProductTypeChip: React.FC<ProductTypeChipProps> = ({
  productType,
  productTypeImage,
  onProductTypeFilterDeleted,
}) => {
  return (
    <StyledProductTypeChip
      label={productType}
      size="small"
      variant="outlined"
      avatar={<Avatar src={productTypeImage} />}
      onDelete={onProductTypeFilterDeleted}
    />
  );
};

type PriceRangeChipProps = {
  min: number | undefined;
  max: number | undefined;
  onPriceRangeFilterDeleted(): void;
};

const PriceRangeChip: React.FC<PriceRangeChipProps> = ({ min, max, onPriceRangeFilterDeleted }) => {
  return (
    <StyledProductTypeChip
      label={`${min} - ${max}`}
      size="small"
      variant="outlined"
      avatar={<Avatar>€</Avatar>}
      onDelete={onPriceRangeFilterDeleted}
    />
  );
};

const chipStyles = css`
  &.MuiChip-root {
    border-color: #48a08b;
    .MuiChip-deleteIcon {
      color: #48a08b;
    }
  }
`;

const StyledProductTypeChip = styled(Chip)(() => chipStyles);

interface Props {
  filters: CreateGangaHuntFilters;
  onProductTypeFilterDeleted(): void;
  onPriceRangeFilterDeleted(): void;
}

const CreateGangaHuntFiltersBar: React.FC<Props> = ({
  filters,
  onProductTypeFilterDeleted,
  onPriceRangeFilterDeleted,
}) => {
  return (
    <div
      tw="flex"
      css={css`
        align-items: center;
      `}
    >
      {filters.productType ? (
        <ProductTypeChip
          productType={filters.productType}
          productTypeImage={filters.productTypeImage}
          onProductTypeFilterDeleted={onProductTypeFilterDeleted}
        />
      ) : null}
      {filters.minPrice || filters.maxPrice ? (
        <PriceRangeChip
          min={filters.minPrice}
          max={filters.maxPrice}
          onPriceRangeFilterDeleted={onPriceRangeFilterDeleted}
        />
      ) : null}
    </div>
  );
};

export default CreateGangaHuntFiltersBar;
