/** @jsxImportSource @emotion/react */
import { css, jsx, Theme } from '@emotion/react';
import React from 'react';
import { Button } from '@material-ui/core';

const styles = (theme: Theme) => css`
  background-color: #f0f0f0;
  border-radius: 0 0 8px;
  border-top: 2px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.3rem 0 0.5rem;

  > .MuiButton-root {
    margin-left: 2em;
  }

  ${theme.breakpoints.up('md')} {
    padding: 0 2rem;
  }
`;

interface SendFeedbackActionsProps {
  sendFeedback(): void;
  cancelSending(): void;
}

const SendFeedbackActions: React.FC<SendFeedbackActionsProps> = ({ sendFeedback, cancelSending }) => {
  return (
    <div css={styles}>
      <Button variant="contained" color="default" onClick={cancelSending}>
        Cancel
      </Button>
      <Button variant="contained" color="primary" onClick={sendFeedback}>
        Send
      </Button>
    </div>
  );
};

export default SendFeedbackActions;
