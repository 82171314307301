import { commitLocalUpdate, Environment } from 'relay-runtime';
import { randomBytes } from 'crypto';

export default {
  initialize(environment: Environment): void {
    commitLocalUpdate(environment, (store) => {
      let viewerId = localStorage.getItem('viewerId');
      if (!viewerId) {
        viewerId = randomBytes(20).toString('hex');
        localStorage.setItem('viewerId', viewerId);
      }

      const root = store.getRoot();
      const viewer = root.getOrCreateLinkedRecord('viewer', 'Viewer');
      viewer.setValue(viewerId, 'id');
    });
  },
};
