import React, { ReactElement } from 'react';
import { useService } from '@xstate/react';
import useSk8terbotRunningMachine from '../../../machines/hooks/useSk8terbotMachine';
import CreateGangaHuntSection from './CreateGangaHuntSection';
import {
  CreateGangaHuntMachineContextContract,
  CreateGangaHuntMachineService,
} from '../../../machines/definitions/shopping/createGangaHuntMachine';
import AddGangasMessageMutation from '../../../data/relay/messages/mutations/AddGangasMessageMutation';
import { useRelayEnvironment } from 'react-relay/hooks';
import useDIContainer from '../../../diContainer/hooks/useDIContainer';

const CreateGangaHuntSectionContainer: React.FC = () => {
  const relayEnvironment = useRelayEnvironment();
  const [mainState] = useService(useSk8terbotRunningMachine());
  const createGangaHunt = mainState.children.createGangaHunt as CreateGangaHuntMachineService;
  const diContainer = useDIContainer();

  if (createGangaHunt && createGangaHunt.machine) {
    createGangaHunt.machine.options.services['loadProductsByType'] = (context: CreateGangaHuntMachineContextContract) =>
      new Promise<void>((resolve) => {
        AddGangasMessageMutation.commit(
          relayEnvironment,
          context.productType ?? '',
          context.minPrice ?? 0,
          context.maxPrice ?? 99999
        );
        return resolve();
      });

    createGangaHunt.machine.options.services['loadMoreProductsByType'] = (
      context: CreateGangaHuntMachineContextContract
    ) =>
      new Promise<void>((resolve) => {
        const gangaSearchRepository = diContainer.gangasSearchRepository;
        const lastSearch = gangaSearchRepository.getLast();
        const afterCursor = lastSearch?.endCursor ?? '';
        AddGangasMessageMutation.commit(
          relayEnvironment,
          context.productType ?? '',
          context.minPrice ?? 0,
          context.maxPrice ?? 99999,
          afterCursor
        );
        return resolve();
      });
  }

  return <CreateGangaHuntSection machineService={createGangaHunt} />;
};

export default CreateGangaHuntSectionContainer;
