import React from 'react';
import { useService } from '@xstate/react';
import ProductTypeComboBox from './ProductTypeComboBox';
import { CreateGangaHuntMachineService } from '../../../../machines/definitions/shopping/createGangaHuntMachine';

interface Props {
  machineService: CreateGangaHuntMachineService;
}

const ProductTypeComboBoxContainer: React.FC<Props> = ({ machineService }) => {
  const [_, send] = useService(machineService);

  const onSelectedProductType = (selectedProductType: string, productTypeImage: string) => {
    send({ type: 'SELECTED_PRODUCT_TYPE', productType: selectedProductType, productTypeImage });
  };

  return <ProductTypeComboBox onSelectedProductType={onSelectedProductType} />;
};

export default ProductTypeComboBoxContainer;
