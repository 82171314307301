import { commitLocalUpdate, ConnectionInterface, Environment } from 'relay-runtime';

const { EDGES } = ConnectionInterface.get();

export default {
  initialize(environment: Environment): void {
    commitLocalUpdate(environment, (store) => {
      const root = store.getRoot();
      const allMessagesConnection = root.getOrCreateLinkedRecord('allMessages', 'MessageConnection');
      allMessagesConnection.setLinkedRecords([], EDGES);
    });
  },
};
