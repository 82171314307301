import { UserInput as ApiUserInput } from '../../../../../types/__graphql__/types';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import { graphql } from 'babel-plugin-relay/macro';
import { UserInputRepositoryGetLastQueryResponse } from '../../../../../__relay_artifacts__/UserInputRepositoryGetLastQuery.graphql';
import UserInput from '../../../../../userInput/ UserInput';
import UserInputFactory from '../factories/UserInputFactory';
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';

class UserInputRepository {
  private _relayEnvironment: RelayModernEnvironment;

  constructor(relayEnvironment: RelayModernEnvironment) {
    this._relayEnvironment = relayEnvironment;
  }

  static readonly getLastQuery = graphql`
    query UserInputRepositoryGetLastQuery {
      ... on Query {
        __typename
      }
      sk8terbot {
        state {
          userInputQueue {
            __typename
            kind
          }
        }
      }
    }
  `;

  public getLast(): UserInput | undefined {
    const request = getRequest(UserInputRepository.getLastQuery);
    const operation = createOperationDescriptor(request, {});
    this._relayEnvironment.check(operation);
    const res = this._relayEnvironment.lookup(operation.fragment);
    if (res === undefined) {
      return undefined;
    }

    const data = res.data as UserInputRepositoryGetLastQueryResponse;
    const lastUserInput = (data.sk8terbot?.state?.userInputQueue?.[0] as ApiUserInput) ?? undefined;
    if (lastUserInput === undefined) {
      return undefined;
    }

    return UserInputFactory.createDomainTypeFromApiType(lastUserInput);
  }
}

export default UserInputRepository;
