import { generateClientID, RecordProxy, RecordSourceProxy, ROOT_ID } from 'relay-runtime';
import UserInput from '../../../../../userInput/ UserInput';
import { getStableStorageKey } from 'relay-runtime/lib/store/RelayStoreUtils';
import IDoNotLikeAnyProductInput from '../../../../../userInput/IDoNotLikeAnyProductInput';
import { UserInput as ApiUserInput, UserInputKind as ApiUserInputKind } from '../../../../../types/__graphql__/types';
import ProductTypeSelectedInput from '../../../../../userInput/ProductTypeSelectedInput';
import {
  isIDoNotLikeAnyProductInput,
  isIWantToLeaveMyFeedbackInput,
  isIWantToStartAGangaHuntInput,
  isShowMeMoreProductsOfSameTypeInput,
  isShowMeProductsOfDifferentTypeInput,
} from '../../../../../types/__graphql__/userInputGuards';
import ShowMeMoreProductsOfSameType from '../../../../../userInput/ShowMeMoreProductsOfSameType';
import ShowMeProductsOfDifferentType from '../../../../../userInput/ShowMeProductsOfDifferentType';
import IWantToStartAGangaHuntInput from '../../../../../userInput/IWantToStartAGangaHuntInput';
import IWantToLeaveMyFeedbackInput from '../../../../../userInput/IWantToLeaveMyFeedbackInput';

export default class UserInputFactory {
  private static nextUserInputId = 1;

  static createRecordFromDomainType(store: RecordSourceProxy, input: UserInput): RecordProxy | undefined {
    const Sk8terbotState = store.get(`${ROOT_ID}:sk8terbot:state`);

    if (Sk8terbotState) {
      const storageKey = getStableStorageKey(`userInputQueue:${UserInputFactory.nextUserInputId++}`, []);
      const clientID = generateClientID(Sk8terbotState.getDataID(), storageKey);
      const record = store.create(clientID, 'UserInput');
      record.setValue('UserInput', '__typename');
      if (input instanceof IWantToStartAGangaHuntInput) {
        record.setValue(ApiUserInputKind.IWantToStartAGangaHunt, 'kind');
      }
      if (input instanceof IDoNotLikeAnyProductInput) {
        record.setValue(ApiUserInputKind.IDoNotLikeAnyProduct, 'kind');
      }
      if (input instanceof ShowMeMoreProductsOfSameType) {
        record.setValue(ApiUserInputKind.ShowMeMoreProductsOfSameType, 'kind');
      }
      if (input instanceof ShowMeProductsOfDifferentType) {
        record.setValue(ApiUserInputKind.ShowMeProductsOfDifferentType, 'kind');
      }
      if (input instanceof ProductTypeSelectedInput) {
        record.setValue(ApiUserInputKind.SelectedProductType, 'kind');
        record.setValue(input.productType, 'productType');
      }
      if (input instanceof IWantToLeaveMyFeedbackInput) {
        record.setValue(ApiUserInputKind.IWantToLeaveMyFeedback, 'kind');
      }

      return record;
    }
  }

  static createDomainTypeFromApiType(apiUserInput: ApiUserInput): UserInput {
    if (isIWantToStartAGangaHuntInput(apiUserInput)) {
      return new IWantToStartAGangaHuntInput();
    }
    if (isIDoNotLikeAnyProductInput(apiUserInput)) {
      return new IDoNotLikeAnyProductInput();
    }
    if (isShowMeMoreProductsOfSameTypeInput(apiUserInput)) {
      return new ShowMeMoreProductsOfSameType();
    }
    if (isShowMeProductsOfDifferentTypeInput(apiUserInput)) {
      return new ShowMeProductsOfDifferentType();
    }
    if (isIDoNotLikeAnyProductInput(apiUserInput)) {
      return new IDoNotLikeAnyProductInput();
    }
    if (isIWantToLeaveMyFeedbackInput(apiUserInput)) {
      return new IWantToLeaveMyFeedbackInput();
    }

    throw new Error('user input type is not recognized!');
  }
}
