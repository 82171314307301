/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { ChangeEvent, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Ganga } from '../../../../types/__graphql__/types';
import { isGanga } from '../../../../types/__graphql__/gangaGuards';
import { getGangaImage } from '../../../../helpers/gangasHelper';
import styled from '@emotion/styled';
import { unstable_trace as trace } from 'scheduler/tracing';

type Product = Pick<Ganga, 'id' | 'sellerId' | 'title' | 'imageUrl'> & { __typename: string };

const autocomboStyles = css`
  font-size: 0.8rem;
  color: #e0e0e0;
  background-color: white;
  width: 95%;
  border: 1px solid #e0e0e0;
  margin-right: 0.5rem;
`;

const ProductAvatar = styled(Avatar)`
  margin-right: 0.3rem;
`;

const propTypes = {
  gangas: PropTypes.array.isRequired,
  onInput: PropTypes.func.isRequired,
};

type Props = {
  gangas: ReadonlyArray<Product | null>;
  onInput(userInput: ProductComboBoxOption): void;
};

export type ProductComboBoxOption = Product | ProductComboBoxFixedOptions | null;

export enum ProductComboBoxFixedOptions {
  ShowMeMore = 'show_me_more',
  ShowMeOtherProductTypes = 'show_me_other_product_types',
  StopGangaHunt = 'stop_ganga_hunt',
}

const ProductComboBox: React.FC<Props> = ({ gangas, onInput }) => {
  const { t } = useTranslation(['inputs', 'productTypes']);

  const handleProductLiked = useCallback(
    (event: ChangeEvent<unknown>, option: ProductComboBoxOption, reason: AutocompleteChangeReason) => {
      if (reason === 'select-option') {
        trace('[Create GangaHunt]: the customer selected an option on the Product combo box', performance.now(), () => {
          onInput(option);
        });
      }
    },
    [onInput]
  );

  return (
    <React.Fragment>
      <Autocomplete<ProductComboBoxOption>
        data-test-id="likable-product-autocomplete"
        ListboxProps={{
          'data-test-id': 'likable-product-autocomplete-listbox',
        }}
        size={'small'}
        options={[
          ...gangas,
          ProductComboBoxFixedOptions.ShowMeMore,
          ProductComboBoxFixedOptions.ShowMeOtherProductTypes,
          ProductComboBoxFixedOptions.StopGangaHunt,
        ]}
        groupBy={(option) => {
          if (isGanga(option)) {
            return t('inputs:likable_products.i_like_it.group_header');
          }

          return t('inputs:likable_products.i_like_none.group_header');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('inputs:likable_products.placeholder')}
            variant="outlined"
            data-test-id="likable-product-autocomplete-input"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        getOptionLabel={(option) => {
          if (isGanga(option)) {
            return option.title;
          }
          if (option === ProductComboBoxFixedOptions.ShowMeMore) {
            return t('inputs:likable_products.i_like_none.show_me_more');
          }
          if (option === ProductComboBoxFixedOptions.ShowMeOtherProductTypes) {
            return t('inputs:likable_products.i_like_none.show_me_other_product_type');
          }
          if (option === ProductComboBoxFixedOptions.StopGangaHunt) {
            return t('inputs:likable_products.i_like_none.stop_ganga_hunt');
          }
          return '';
        }}
        renderOption={(option) => {
          if (isGanga(option)) {
            return (
              <React.Fragment>
                <ProductAvatar variant="square" alt={option.title} src={getGangaImage(option)} />
                {option.title}
              </React.Fragment>
            );
          }
          if (option === ProductComboBoxFixedOptions.ShowMeMore) {
            return <React.Fragment>{t('inputs:likable_products.i_like_none.show_me_more')}</React.Fragment>;
          }
          if (option === ProductComboBoxFixedOptions.ShowMeOtherProductTypes) {
            return (
              <React.Fragment>{t('inputs:likable_products.i_like_none.show_me_other_product_type')}</React.Fragment>
            );
          }
          if (option === ProductComboBoxFixedOptions.StopGangaHunt) {
            return <React.Fragment>{t('inputs:likable_products.i_like_none.stop_ganga_hunt')}</React.Fragment>;
          }
        }}
        css={autocomboStyles}
        onChange={handleProductLiked}
      />
    </React.Fragment>
  );
};

ProductComboBox.propTypes = propTypes;

export default ProductComboBox;
