import UserInput from './ UserInput';

class ProductTypeSelectedInput extends UserInput {
  private _productType: string;

  constructor(productType: string) {
    super();
    this._productType = productType;
  }

  get productType(): string {
    return this._productType;
  }
}

export default ProductTypeSelectedInput;
