import { commitLocalUpdate, ConnectionHandler, Environment, RecordProxy } from 'relay-runtime';
import MessageFactory from '../factories/MessageFactory';
import { assertIsDefined } from '@gangabots/types/dist/assertions';
import { MessageSender } from '../../../../types/__graphql__/types';

function commit(environment: Environment, text: string): void {
  commitLocalUpdate(environment, (store) => {
    const allMessages = store.get('client:root:allMessages');
    assertIsDefined<RecordProxy>(allMessages);

    ConnectionHandler.insertEdgeAfter(
      allMessages,
      ConnectionHandler.createEdge(
        store,
        allMessages,
        MessageFactory.makeTextMessage(store, MessageSender.Customer, text),
        'MessageEdge'
      )
    );
  });
}

export default { commit };
