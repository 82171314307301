import React, { useEffect } from 'react';
import SendingFeedbackSection from './SendingFeedbackSection';
import { useService } from '@xstate/react';
import useSk8terbotRunningMachine from '../../../machines/hooks/useSk8terbotMachine';
import {
  isSendFeedbackEvent,
  SendFeebackMachineService,
  SendFeedbackMachineContextContract,
  SendFeedbackMachineEvent,
} from '../../../machines/definitions/sendFeedbackMachine';
import { useGA4React } from 'ga-4-react';
import { GA4ReactResolveInterface } from 'ga-4-react/dist/models/gtagModels';

const SendingFeedbackSectionContainer: React.FC = () => {
  const [mainState] = useService(useSk8terbotRunningMachine());
  const ga4 = useGA4React() as GA4ReactResolveInterface;
  const sendFeedbackService = mainState.children.sendFeedback as SendFeebackMachineService;

  useEffect(() => {
    ga4?.pageview('feedback_page', 'feedback_page', 'feedback_page');
  }, [ga4]);

  const sendFeedback = async (email: string, message: string): Promise<void> => {
    const url = `${process.env.REACT_APP_REST_FEEDBACK_ENDPOINT}/user-events/feedback`;
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, message }),
    };

    await fetch(url, request);
  };

  if (sendFeedbackService && sendFeedbackService.machine) {
    sendFeedbackService.machine.options.services['sendFeedback'] = (
      context: SendFeedbackMachineContextContract,
      event: SendFeedbackMachineEvent
    ) => {
      if (!isSendFeedbackEvent(event)) {
        return Promise.resolve();
      }

      return sendFeedback(event.email, event.message);
    };
  }

  return <SendingFeedbackSection machineService={sendFeedbackService} />;
};

export default SendingFeedbackSectionContainer;
