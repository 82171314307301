import { createOperationDescriptor, getRequest } from 'relay-runtime';
import { graphql } from 'babel-plugin-relay/macro';
import { ViewerRepositoryGetIdQueryResponse } from '../../../../__relay_artifacts__/ViewerRepositoryGetIdQuery.graphql';
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';

class ViewerRepository {
  private _relayEnvironment: RelayModernEnvironment;

  constructor(relayEnvironment: RelayModernEnvironment) {
    this._relayEnvironment = relayEnvironment;
  }

  static readonly getIdQuery = graphql`
    query ViewerRepositoryGetIdQuery {
      viewer {
        id
      }
    }
  `;

  public getId(): string | undefined {
    const request = getRequest(ViewerRepository.getIdQuery);
    const operation = createOperationDescriptor(request, {});
    this._relayEnvironment.check(operation);
    const res = this._relayEnvironment.lookup(operation.fragment);
    if (res === undefined) {
      return undefined;
    }

    return (res.data as ViewerRepositoryGetIdQueryResponse).viewer.id;
  }
}

export default ViewerRepository;
