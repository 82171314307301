import { Toolbar } from '@material-ui/core';
import styled from '@emotion/styled';

const MainToolbar = styled(Toolbar)`
  ${({ theme }) => `
    background-color: #ededed;
    color: black;
    border-bottom: 2px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    ${theme.breakpoints.up('md')} {
      font-size: 1.2rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  `}
`;

export default MainToolbar;
