import React, { useEffect } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { loadQuery, usePreloadedQuery } from 'react-relay/hooks';
import relayEnvironment from '../../data/relay/environment';
import { MainPageRelayMessagesProviderQuery } from '../../__relay_artifacts__/MainPageRelayMessagesProviderQuery.graphql';

const queryDefinition = graphql`
  query MainPageRelayMessagesProviderQuery {
    ... on Query {
      __typename
    }
    allMessages {
      edges {
        node {
          ... on Message {
            __typename
            id
            sender
            ... on TextMessage {
              text
            }
            ... on GangasMessage {
              productType
              priceStart
              priceEnd
              afterCursor
            }
          }
        }
      }
    }
  }
`;

const preloadedQuery = loadQuery<MainPageRelayMessagesProviderQuery>(
  relayEnvironment,
  queryDefinition,
  {},
  { fetchPolicy: 'store-or-network' }
);

interface Props {
  setAllMessages(messages: any): any;
}

const MainPageRelayMessagesProvider: React.FC<Props> = ({ setAllMessages }) => {
  const { allMessages } = usePreloadedQuery<MainPageRelayMessagesProviderQuery>(queryDefinition, preloadedQuery);

  useEffect(() => setAllMessages(allMessages));

  return null;
};

export default MainPageRelayMessagesProvider;
