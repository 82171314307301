import { Handler } from 'relay-runtime/lib/store/RelayStoreTypes';
import { RelayDefaultHandlerProvider } from 'relay-runtime/lib/handlers/RelayDefaultHandlerProvider';

export function HandlerProvider(handle: string): Handler {
  switch (handle) {
    default:
      return RelayDefaultHandlerProvider(handle);
  }
}

export default HandlerProvider;
